<template>
  <v-container fluid class="grey lighten-3">

    <v-card>
      <v-card-title>
        {{ item.name }}
      </v-card-title>

      <v-card-text>
        <v-container fluid>

          <v-row>
            <v-col md="6">
              <v-form ref="form">
                <v-text-field v-model="item.name" label="Name" disabled></v-text-field>
                <v-text-field v-model="item.iso2" label="ISO2" disabled></v-text-field>
                <v-switch v-model="item.is_active" label="Active"></v-switch>
              </v-form>
            </v-col>
            <v-col md="6">
            </v-col>
          </v-row>
        </v-container>
      </v-card-text>

      <v-card-actions class="pl-5 pb-5">
        <v-btn class="mr-2" :to="{ name: 'Geos' }">Back</v-btn>
        <v-btn color="primary" :loading="saving" @click="save()">Save</v-btn>

        <v-fade-transition mode="out-in">
          <v-btn color="green darken-1" text v-show="showSaved">Saved</v-btn>
        </v-fade-transition>

      </v-card-actions>

    </v-card>

  </v-container>
</template>

<script>
import formsMixin from '@/mixins/forms'

export default {
  name: 'CountryDetail',

  props: {
    id: { type: Number, required: true },
  },

  data () {
    return {
      item: {
        name: null,
        iso2: null,
        is_active: null,
      },
      valid: false,
      loading: false,
      saving: false,
      showSaved: false,
    }
  },

  computed: {
    dataEndpoint () {
      return '/a/common/countries/' + this.id
    },
  },

  components: {
  },

  mixins: [formsMixin],

  methods: {
    fetchData: function () {
      this.loading = true

      // reset data (to bring back deleted item fields)
      Object.assign(this.$data, this.$options.data.apply(this))

      var myURL = this.dataEndpoint + '?xfields='
      // only get the fields needed to fill the item object
      myURL = this.forms_addItemFieldsToURI(myURL, this.item, [])

      this.$http.get(myURL).then(resp => {
        this.item = resp.data.data
      })
      .catch(errResp => {
        this.$store.commit('system/setAPIError', { method: 'GET', url: myURL, description: errResp.response.data.err_description, ext_msg: errResp.response.data.external_message })
      })
      .finally(() => this.loading = false)
    },

    save: function () {
      // make sure all mandatory fields have a value
      if (!this.$refs.form.validate()) {
        return
      }
      this.saving = true

      // only send the required item fields to API
      delete this.item['id']
      delete this.item['name']
      delete this.item['iso2']
      delete this.item['entry_at']
      delete this.item['last_modified_at']

      var body = JSON.stringify(this.item)
      this.$http.put(this.dataEndpoint, body).then(resp => {
        this.showSaved = true
        this.timer = setTimeout(() => { this.showSaved = false }, 3000)
        this.fetchData()
      })
      .catch(errResp => {
        this.$store.commit('system/setAPIError', { method: 'PUT', url: this.dataEndpoint, description: errResp.response.data.err_description, ext_msg: errResp.response.data.external_message })
      })
      .finally(() => this.saving = false)
    },
  },

  created: function () {
    this.fetchData()
  },
}
</script>
